/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../../shared/store/data-Slice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useAxios from "../../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import { CloseBlack, TableSearch } from "../../../common/svg/AllSvg";
import { url } from "../../../../shared/utils/url";

const AssignComplaint = () => {
    const assignComplaint: boolean = useSelector((state: any) => state.data.assignComplaint);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [data, setData] = useState<any>(null)
    const [com, setCom] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [comLoading, setComLoading] = useState<boolean>(true)
    const [search, setSearch] = useState<string>("")
    const [searchFocus, setSearchFocus] = useState<boolean>(false)
    const { t } = useTranslation('common');
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setAssignComplaint(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setAssignComplaint(false))
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    };

    const getUsers = async () => {
        try {
            let api: any = ""
            if (!!search) {
                api = await useAxios.get(`/${(!!lang ? lang : "en") + url?.user_search + search}/`);
            } else {
                api = await useAxios.get(`/${(!!lang ? lang : "en") + url?.user_search}`);
            }
            if (!!api) {
                const res: any = api;

                if (res && res.status === 200) {
                    res?.data?.results?.length > 0 ? setData(res?.data?.results) : setData([])
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error("error while fetching users", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getUsers();
    }, [lang, params?.id, search])

    const handleAssign = async (item: any) => {
        try {
            if (item?.email === com?.assigned_user_details?.email) {
                dispatch(dataActions.setAssignComplaint(false))
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            } else {
                const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.assign_complaint + params?.id}/`, { assigned: true, assigned_user: item?.user_id, assigned_department: item?.department_id, status: com?.status });

                if (res && res.status === 200) {
                    dispatch(dataActions.setComplaintApiCall(true));
                    dispatch(dataActions.setAssignComplaint(false))
                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                }
            }
        } catch (error) {
            console.error("error while assign user", error);
        }
    }

    const getComplaint = async () => {
        try {
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.complaint_details + params?.id}`);

            if (res && res.status === 200 && res?.data?.length > 0) {
                setCom(res?.data?.[0])
                setComLoading(false)
            }
        } catch (error) {
            console.error("error while fetching complaint detail", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getComplaint();
    }, [lang, params?.id])

    return (
        <>
            {assignComplaint &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto bg-white rounded-[10px] w-[275px] ss:w-[320px] h-auto sm:w-[432px] relative shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)]" ref={ref}>
                            <div className="flex flex-col w-full gap-[15px] px-[20px] py-[15px]">
                                <div className="flex w-full justify-between items-center">
                                    <h4 className="text-primaryText font-semibold">{t("grievance.home.assign.assign_this")}</h4>

                                    <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e]" type="button" onClick={() => {
                                        dispatch(dataActions.setAssignComplaint(false))
                                        !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                    }}>
                                        <CloseBlack />
                                    </button>
                                </div>

                                <div className={`border border-[#EFEFEF] bg-[#F6F6F6] h-[35px] xlx:h-[40px] px-[12px] xlx:px-[15px] flex items-center gap-[6px] xlx:gap-[9px] rounded-[11px] w-full  ${searchFocus && "shadow-xl"} hover:shadow-xl`}>
                                    <TableSearch className="xlx:w-[18px] xlx:min-w-[18px] xlx:h-[18px] min-w-[15px] w-[15px] h-[15px] fill-primary" />
                                    <input type="text" className="text-blackDull font-medium placeholder:text-opacity-[0.7] placeholder:text-blackDull text-sm leading-tight w-full bg-transparent" placeholder={t("grievance.home.assign.search")} autoComplete="off" value={search} onChange={(e: any) => { setSearch(e.target.value) }} onFocus={() => setSearchFocus(true)} onBlur={() => setSearchFocus(false)} />
                                </div>
                            </div>

                            {(loading || comLoading) ?
                                <div className="w-full flex items-center justify-center py-[20px]">
                                    <h5 className="text-primary font-black text-2xl max-w-[200px] text-center flex items-center uppercase gap-[2px]">Loading
                                        <div className="loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </h5>
                                </div>
                                : <div className="flex flex-col gap-[4px] pb-[15px]">
                                    {Array.isArray(data) && data?.map((item: any, index: number) => (
                                        <button key={index} className={`${item?.email === com?.assigned_user_details?.email ? "bg-[#D9D9D9] bg-opacity-[0.3]" : "hover:bg-[#D9D9D9] hover:bg-opacity-[0.3] focus:bg-[#D9D9D9] focus:bg-opacity-[0.3]"} px-[20px] py-[10px] flex items-center gap-[15px] relative`} onClick={() => handleAssign(item)}>
                                            {item?.email === com?.assigned_user_details?.email && <div className="h-full w-[5px] bg-primary absolute left-0 top-0 bottom-0"></div>}
                                            <div className="h-[35px] xlx:h-[40px] min-w-[35px] xlx:min-w-[40px] flex items-center justify-center rounded-full text-[#015293] text-[12px] xlx:text-[14px] font-semibold bg-[#F6FD8D] border border-[#42FD00] leading-tight uppercase">
                                                {item?.first_name?.charAt(0)}{item?.last_name?.charAt(0)}
                                            </div>
                                            <div className="flex flex-col gap-[2px]">
                                                <h5 className="text-primaryText font-bold text-left">{item?.first_name + " " + item?.last_name}</h5>
                                                <p className="text-blackDull font-semibold text-left">{item?.department}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default AssignComplaint;