/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { CheckEye, Eye, CloseBtn, Warn } from "../svg/AllSvg";
import useAxios from "../../../shared/hooks/use-axios";
import { url } from "../../../shared/utils/url";
import SecondaryBtn from "../form/SecondaryBtn";
import Captcha from "../form/Captcha";

const Register = ({ type }: any) => {
  const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
  const lang: string = useSelector((state: any) => state.ui.lang);
  const [hide, setHide] = useState<boolean>(true);
  const [hide1, setHide1] = useState<boolean>(true);
  const [submit, setSubmit] = useState<boolean>(false);
  const [passFcs, setPassFcs] = useState<boolean>(false);
  const [cnfPassFcs, setCnfPassFcs] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [captchaValue, setCaptchaValue] = useState<string>("")
  const [captchaError, setCaptchaError] = useState<string>("")
  const [captchaExpirationTime, setCaptchaExpirationTime] = useState(Date.now() + (5 * 60 * 1000));
  const [captchaInput, setCaptchaInput] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const ref: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dataActions.setRegisterOpen(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  document.onkeydown = function (evt: any) {
    if (evt.keyCode === 27) {
      dispatch(dataActions.setRegisterOpen(false))
    }
  };

  const handlePassword = () => {
    setHide(!hide);
  };

  const handlePassword1 = () => {
    setHide1(!hide1);
  };

  function isEmail(value: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  function isMobileNumber(value: any) {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(value);
  }

  function checkValueType(value: any) {
    if (isEmail(value)) {
      return 'email';
    } else if (isMobileNumber(value)) {
      return 'mobile';
    } else {
      return 'invalid';
    }
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password2: "",
      phone: ""
    },
    validationSchema: type === "passenger" ?
      yup.object().shape({
        first_name: yup.string().required(t("auth.form.validation.first_name")),
        last_name: yup.string().required(t("auth.form.validation.last_name")),
        email: yup.string()
          .test(
            'email-phone-validation',
            t("auth.form.validation.wrong_email_phone"),
            function (value: any) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              const mobileRegex = /^[6-9]\d{9}$/;

              if (value) {
                return emailRegex.test(value) || mobileRegex.test(value);
              }
              return false;
            }
          )
          .required(t("auth.form.validation.email_or_phone")),
        password: yup.string().matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/, t("auth.form.validation.wrong_password")).required(t("auth.form.validation.password")),
        password2: yup.string().oneOf([yup.ref("password"), ""], t("auth.form.validation.match_password")).required(t("auth.form.validation.confirm_password"))
      })
      : yup.object().shape({
        first_name: yup.string().required(t("auth.form.validation.first_name")),
        last_name: yup.string().required(t("auth.form.validation.last_name")),
        email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
        password: yup.string().matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/, t("auth.form.validation.wrong_password")).required(t("auth.form.validation.password")),
        password2: yup.string().oneOf([yup.ref("password"), ""], t("auth.form.validation.match_password")).required(t("auth.form.validation.confirm_password")),
        phone: yup.number().test('len', t("auth.form.validation.wrong_phone"), (val: any) => val && val.toString().length === 10).required(t("auth.form.validation.phone")),
      }),
    onSubmit: async (values: any, { resetForm }: any) => {
      //submit
      if (!!!captchaValue) {
        setCaptchaError(t("auth.form.validation.captcha"))
        return;
      }

      if (captchaInput !== captchaValue) {
        setCaptchaError(t("auth.form.validation.match_captcha"));
        return;
      }
      try {
        setSubmit(true)
        let obj: any = {};
        let fieldType: any = '';
        if (type === "passenger") {
          fieldType = await checkValueType(values.email)
          if (fieldType === "email") {
            obj.email = values.email;
            obj.mobile = "";
          } else {
            obj.mobile = values.email;
            obj.email = "";
          }
          obj.first_name = values?.first_name;
          obj.last_name = values?.last_name;
          obj.password = values?.password;
          obj.password2 = values?.password2;
        } else {
          obj = {
            first_name: values?.first_name,
            last_name: values?.last_name,
            email: values?.email,
            password: values?.password,
            password2: values?.password2,
            mobile_no: values?.phone
          }
        }
        const res: any = await useAxios.post(type === "passenger" ? `/${(!!lang ? lang : "en") + url?.registeration}` : url?.candidate_registeration, obj);

        if (res && res?.status === 201) {
          if (type === "passenger") {
            localStorage.setItem("field_value", values?.email)
            localStorage.setItem("auth_type", "registration")
            localStorage.setItem("field", fieldType)
            resetForm({ values: '' });
            dispatch(dataActions.setVerifyEmailOpen(true))
            dispatch(dataActions.setRegisterOpen(false));
            setSubmit(false)
          } else {
            localStorage.setItem("field_value", values?.email)
            localStorage.setItem("auth_type", "registration")
            localStorage.setItem("field", "email")
            setSubmit(false)
            resetForm({ values: '' });
            dispatch(dataActions.setVerifyEmailOpen(true));
            dispatch(dataActions.setRegisterOpen(false));
          }
        }
      } catch (error: any) {
        if (error && error?.response?.status === 400 && !!error?.response?.data?.email) {
          setError(error?.response?.data?.email?.toString())
        }

        if (error && error?.response?.status === 400 && !!error?.response?.data?.mobile) {
          setError(error?.response?.data?.mobile?.toString())
        }
        setSubmit(false)
        console.error("error while register", error);
      }
    }
  });

  return (
    <>
      {registerOpen &&
        <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]">
          <div className="flex justify-center items-center min-h-screen">
            <div className="relative m-[15px] mm:mx-auto dark:bg-bgDarkDull bg-white rounded-[15px] w-full xlsm:w-[520px] h-full lg:min-h-[670px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)" ref={ref}>
              <form className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                <div className="flex w-full justify-between">
                  <div className="flex flex-col w-full xlx:gap-[4px]">
                    <h3 className="font-bold text-primaryText dark:text-white">{t("auth.registerTitle")}</h3>
                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.registerDesc")}</h6>
                  </div>
                  <button type="button" aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" onClick={() => dispatch(dataActions.setRegisterOpen(false))}>
                    <CloseBtn />
                  </button>
                </div>
                <div className="flex flex-col gap-[18px] pt-[20px] w-full">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-[10px]">
                    <div className="flex flex-col gap-[4px]">
                      <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.first_name")}</h6>
                      <input className="placeholder:text-blackDull rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="text" name="first_name" value={formik?.values?.first_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" placeholder={t("auth.form.field.first_name")} />
                      {formik?.touched?.first_name && formik?.errors?.first_name && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.first_name}</span>
                      </div>}
                    </div>

                    <div className="flex flex-col gap-[4px]">
                      <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.last_name")}</h6>
                      <input className="placeholder:text-blackDull rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="text" name="last_name" value={formik?.values?.last_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" placeholder={t("auth.form.field.last_name")} />
                      {formik?.touched?.last_name && formik?.errors?.last_name && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.last_name}</span>
                      </div>}
                    </div>
                  </div>
                  {type !== "passenger" ?
                    <>
                      <div className="flex flex-col gap-[4px]">
                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.email")}</h6>
                        <input className="placeholder:text-blackDull rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="email" name="email" placeholder={t("auth.form.field.email")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" />
                        {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                          <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.email}</span>
                        </div>}
                        {(!!error && !!!formik?.errors?.email) && <div className="text-danger dark:text-darkDanger text-xs flex gap-[6px]"><Warn /> {t("auth.form.validation.exist_email")}</div>}
                      </div>

                      <div className="flex flex-col gap-[4px]">
                        <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.phone")}</h6>
                        <div className="flex items-center gap-[10px] w-full">
                          <input className="placeholder:text-blackDull flex items-center justify-center px-[7px] w-[35px] xlx:w-[40px] h-[35px] xlx:h-[40px] rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" defaultValue="+91" disabled autoComplete="off" />
                          <input className="placeholder:text-blackDull w-full rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="number" name="phone" placeholder={t("auth.form.field.phone")} autoComplete="off" value={formik?.values?.phone} onBlur={formik?.handleBlur} onChange={formik?.handleChange} onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                            onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} />
                        </div>
                        {formik?.touched?.phone && formik?.errors?.phone && <div className="flex gap-[6px] items-start"><Warn />
                          <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.phone}</span>
                        </div>}
                      </div>
                    </> :
                    <div className="flex flex-col gap-[4px]">
                      <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.email_or_phone")}</h6>
                      <input className="rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull placeholder:text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull" type="text" name="email" placeholder={t("auth.form.field.email_or_phone")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} autoComplete="off" />
                      {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                        <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.email}</span>
                      </div>}
                      {(!!error && !!!formik?.errors?.email) && <div className="text-danger dark:text-darkDanger text-xs flex gap-[6px]"><Warn /> {t("auth.form.validation.exist_email_phone")}</div>}
                    </div>
                  }

                  <div className="flex flex-col gap-[4px]">
                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.password")}</h6>
                    <div className={`rounded-[6px] border dark:border-[#454545] border-[#D7D8DD] h-[35px] xlx:h-[40px] w-full relative flex items-center ${passFcs ? "bg-white dark:bg-bgDarkDull" : "hover:bg-white bg-[#F5F5F5] dark:hover:bg-bgDarkDull dark:bg-bgDark"}`}>
                      <input className="rounded-[6px] bg-transparent w-full text-blackDull text-sm leading-snug h-full pl-[15px] xlx:pl-[17px] pr-[40px] placeholder:text-blackDull dark:text-textBlackDull placeholder:dark:text-textBlackDull" type={hide ? "password" : "text"} name="password" placeholder={t("auth.form.field.password")} value={formik?.values?.password} onBlur={(e: any) => { formik?.handleBlur(e); setPassFcs(false) }} onChange={formik?.handleChange} autoComplete="off" onFocus={() => setPassFcs(true)} />

                      <button type="button" className="absolute right-[10px] cursor-pointer h-full" onClick={handlePassword}>
                        {hide ? <CheckEye /> : <Eye />}
                      </button>
                    </div>
                    {formik?.touched?.password && formik?.errors?.password && <div className="flex gap-[6px] items-start"><Warn />
                      <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.password}</span>
                    </div>}
                  </div>

                  <div className="flex flex-col gap-[4px]">
                    <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("auth.form.field.confirm_password")}</h6>
                    <div className={`rounded-[6px] border dark:border-[#454545] border-[#D7D8DD] h-[35px] xlx:h-[40px] w-full relative flex items-center ${cnfPassFcs ? "bg-white dark:bg-bgDarkDull" : "hover:bg-white bg-[#F5F5F5] dark:hover:bg-bgDarkDull dark:bg-bgDark"}`}>
                      <input className="rounded-[6px] bg-transparent w-full text-blackDull text-sm leading-snug h-full pl-[15px] xlx:pl-[17px] pr-[40px] placeholder:text-blackDull dark:text-textBlackDull placeholder:dark:text-textBlackDull" type={hide1 ? "password" : "text"} name="password2" placeholder={t("auth.form.field.confirm_password")} value={formik?.values?.password2} onBlur={(e: any) => { formik?.handleBlur(e); setCnfPassFcs(false) }} onChange={formik?.handleChange} autoComplete="off" onFocus={() => setPassFcs(true)} />

                      <button type="button" className="absolute right-[10px] cursor-pointer h-full" onClick={handlePassword1}>
                        {hide1 ? <CheckEye /> : <Eye />}
                      </button>
                    </div>
                    {formik?.touched?.password2 && formik?.errors?.password2 && <div className="flex gap-[6px] items-start"><Warn />
                      <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.password2}</span>
                    </div>}
                  </div>

                  <Captcha captchaValue={captchaValue} setCaptchaValue={setCaptchaValue} captchaError={captchaError} setCaptchaError={setCaptchaError} captchaExpirationTime={captchaExpirationTime} setCaptchaExpirationTime={setCaptchaExpirationTime} captchaInput={captchaInput} setCaptchaInput={setCaptchaInput} />
                </div>

                <SecondaryBtn title={t("auth.registerBtn")} onClick={formik?.handleSubmit} css="w-full flex items-center justify-center uppercase !rounded-[10px] mt-[30px] font-bold" disabled={submit} />

                <div className="flex items-center justify-center w-full pt-[15px] mb-[134px] xlx:mb-[130px]">
                  <div className="text-[#606060] dark:text-[#9b9b9b] text-sm leading-snug font-normal">{t("auth.already_account")} &nbsp;<button className="text-primary text-sm leading-snug font-semibold focus:underline hover:underline" type="button" onClick={() => { dispatch(dataActions.setLoginOpen(true)); dispatch(dataActions.setRegisterOpen(false)); }}>{t("auth.login")}</button></div>
                </div>
              </form>

              <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end">
                <img src={require("../../../assets/images/login-bg.png")} alt="register-bg" className="rounded-b-[15px] w-full" />
              </div>
            </div>
          </div >
        </div >}
    </>
  );
};

export default Register;