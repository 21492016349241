import Select from "react-select";
import useSelect from "../../../shared/hooks/use-select";
import { useState } from "react";
import { useSelector } from "react-redux";

const SelectComponent = ({ options, placeholder, name, title, components, menuIsOpen, onMenuOpen, onMenuClose, onInputChange, inputValue, onChange, value, onFocus, onBlur, isSearchable, arrow, popup }: any) => {
    const theme: any = useSelector((state: any) => state.ui.theme);
    const [selectFocus, setSelectFocus] = useState<boolean>(false)
    let styles = useSelect(arrow, theme);
    // Extend styles with custom menu and menuPortal styles
    styles = {
        ...styles,
        menu: (base: any) => ({
            ...base,
            zIndex: 9999, // Ensure dropdown is above modal content
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999, // Ensure menu portal is above other elements
        }),
    };

    const filterOptions = (
        candidate: { label: string; value: string; data: any },
        input: string
    ) => {
        return true;
    };

    const handleKeyDown = (e: any) => {
        if (e?.key === 'Backspace' && e?.target?.value?.length <= 0) {
            e.preventDefault(); // Prevent the default behavior of the "Backspace" key
        }
    };

    return (
        <div className={`w-full rounded-[10px] border border-[rgba(134,134,134,0.25)] dark:bg-[#191919] bg-[#F8F8F8] flex flex-col  ${popup ? "px-[12px] sm:px-[17px] xlx:px-[22px] xlx:pt-[9px] xlx:pb-[4px] pt-[8px] pb-[2px]" : "pt-[8px] px-[12px] sm:px-[17px]"} hover:shadow-lg hover:bg-white dark:hover:bg-[#4c4e5e5e] ${selectFocus && "shadow-lg bg-white dark:bg-[#4c4e5e5e]"}`}>
            <h6 className="text-blackDark dark:text-textBlackDull font-medium">{title}</h6>
            <Select
                options={options}
                name={name}
                className="w-full mt-[-3px] xlx:mt-[-1px] select-input"
                placeholder={placeholder}
                styles={styles}
                components={components}
                isSearchable={isSearchable}
                menuIsOpen={menuIsOpen}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                onInputChange={onInputChange}
                inputValue={inputValue}
                onChange={onChange}
                value={value}
                noOptionsMessage={() => null}
                onFocus={() => { setSelectFocus(true); onFocus(); }}
                onBlur={() => { setSelectFocus(false); onBlur(); }}
                isClearable
                filterOption={filterOptions}
                onKeyDown={handleKeyDown}
                aria-label={title || placeholder}
                classNamePrefix="react-select-station"
                maxMenuHeight={390}
                menuPortalTarget={document.body}
            />
        </div>
    );
};

export default SelectComponent;
