export const useSelect = (arrow: any, theme: any): any => {
  let styles: any = {
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: "600",
      fontSize: "0.875rem",
      color: state.isSelected ? "#FF4040" : state.isFocused ? "#5A5A5A" : "#5A5A5A",
      backgroundColor: state.isSelected ? "#EDEDED" : state.isFocused ? "#EDEDED" : "#fff",
      cursor: "pointer",
      border: "none",
      "&:hover": {
        backgroundColor: state.isDisabled ? "normal" : "#EDEDED",
        border: "none",
        color: state.isDisabled ? "#686868" : "#686868",
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: "20",
      borderRadius: "10px",
      background: "#fff",
      boxShadow: "0px 10px 32px -4px rgba(24, 39, 75, 0.10), 0px 6px 14px -6px rgba(24, 39, 75, 0.12)"
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "10px"
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: theme === "dark" ? "#CCD0E2" : "#1A1F36",
      fontWeight: "600",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? "#1A1F36" : "#1A1F36",
      fontWeight: "600",
      "&:hover": {
        color: state.isFocused ? "#1A1F36" : "#1A1F36",
      },
      display: arrow ? "block" : "none",
      padding: "0px"
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: "0px",
      cursor: "text"
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      padding: "0px",
      cursor: "pointer"
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none"
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? "0" : "0",
      backgroundColor: state.isFocused ? "none" : "none",
      borderRadius: state.isFocused ? "0" : "0",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        border: state.isFocused ? "0px solid #6B7280" : "0px solid #6B7280",
      },
      minHeight: '31px',
      padding: "0px",
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: theme === "dark" ? "#CCD0E2" : "#1A1F36",
      fontWeight: "600",
      gridTemplateColumns: "0fr",
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: theme === "dark" ? "#CCD0E2" : "#1A1F36",
      fontWeight: "600"
    })
  };

  return styles;
};

export default useSelect;