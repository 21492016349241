import { useDispatch } from 'react-redux';
import { dataActions } from '../../../shared/store/data-Slice';
import { Arohatech, LastUpdated, VisitorNo } from '../svg/AllSvg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useAxios from '../../../shared/hooks/use-axios';
import { url } from '../../../shared/utils/url';

const CommonFooter = ({ type, page }: any) => {
    const [data, setData] = useState<any>(null)
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    const handleLink = (item: any) => {
        dispatch(dataActions.setWebOpen(true));
        dispatch(dataActions.setLink(item));
    }

    const getVisitors = async () => {
        try {
            const res: any = await useAxios.get(url?.grievance_visitors);

            if (res && res.status === 200) {
                setData(res?.data?.results)
            }
        } catch (error) {
            console.error("error while fetching visitor no", error);
        }
    }

    useEffect(() => {
        type === "grievance" && getVisitors();
    }, [type])

    return (
        <div className={`${!!page ? "bg-white" : "bg-[#F6F9FC]"} px-[15px] sm:px-[40px] xl:px-[80px] pt-[12px] mm:pt-[8px] pb-[8px] w-full flex items-center mm:justify-between gap-[10px] mm:gap-[15px] xl:gap-[18px] mm:flex-row flex-col`}>
            <div className="flex items-center gap-[12px] xl:gap-[18px] xxs:flex-row flex-col">
                <h6 className="text-blackDull font-semibold hidden xlsm:block">© {new Date().getFullYear()} <span className="text-primaryText">{t("passenger.home.footer.mmrcl")}</span> {t("passenger.home.footer.all_rights")}</h6>

                <div className="flex items-center gap-[8px] xl:gap-[10px]">
                    <div className="w-[15px] h-[16px] md:w-auto md:h-auto">
                        <LastUpdated />
                    </div>

                    <p className="uppercase text-primaryText font-semibold">{t("passenger.home.footer.last_updated")} : {!!data?.last_updated_date ? moment(data?.last_updated_date).format('MMM DD, YYYY') : "23 Jun, 2024"}</p>
                </div>

                <div className="flex items-center gap-[8px] xl:gap-[10px]">
                    <div className="w-[22px] h-[10px] md:w-auto md:h-auto">
                        <VisitorNo />
                    </div>

                    <p className="uppercase text-primaryText font-semibold">{t("passenger.home.footer.visitor_no")} {!!data?.visitors_count ? data?.visitors_count : 0}</p>
                </div>
            </div>

            <div className="w-full xlsm:w-auto flex items-center justify-between gap-[12px]">
                <h6 className="text-blackDull font-semibold block xlsm:hidden break-all text-center">© {new Date().getFullYear()} <span className="text-primaryText">{t("passenger.home.footer.mmrcl")}</span> {t("passenger.home.footer.all_rights")}</h6>

                <div className="flex items-center xsm:gap-[8px] md:gap-[15px] xsm:flex-row flex-col">
                    <h6 className="text-blackDull font-semibold order-2 xsm:order-1 text-center">{t("passenger.home.footer.designed_by")}</h6>

                    <Link to="https://www.arohatech.com/" target="_blank" aria-label="Visit Arohatech Page" className="min-w-[108px] order-1 xsm:order-2 border border-transparent  hover:border-[#E5E5E5] hover:rounded-[6px] focus:border-[#E5E5E5] focus:rounded-[6px] p-[6px]" onClick={(e) => { handleLink("https://www.arohatech.com/"); e.preventDefault(); }}>
                        <Arohatech />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CommonFooter