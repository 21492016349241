/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import NewComplaint from "../../../components/corporate/grievance/Home/NewComplaint";
import ComplaintDetail from "../../../components/corporate/grievance/Home/ComplaintDetail";
import ResolveComplaint from "../../../components/corporate/grievance/Home/ResolveComplaint";
import AssignComplaint from "../../../components/corporate/grievance/Home/AssignComplaint";
import CommonFooter from "../../../components/common/layout/CommonFooter";

const GrievanceLayout = () => {
    const createComplaint: boolean = useSelector((state: any) => state.data.createComplaint);
    const complaintDetail: boolean = useSelector((state: any) => state.data.complaintDetail);
    const resolveComplaint: boolean = useSelector((state: any) => state.data.resolveComplaint);
    const assignComplaint: boolean = useSelector((state: any) => state.data.assignComplaint);
    const location = useLocation();

    useEffect(() => {
        if (createComplaint || complaintDetail || resolveComplaint || assignComplaint) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [createComplaint, complaintDetail, resolveComplaint, assignComplaint]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {createComplaint && <NewComplaint />}
            {complaintDetail && <ComplaintDetail />}
            {resolveComplaint && <ResolveComplaint />}
            {assignComplaint && <AssignComplaint />}
            <Outlet />
            <CommonFooter type="grievance" />
        </>
    );
};

export default GrievanceLayout;