import Cookies from 'js-cookie';
import { url } from './url';

export const storeCookie = (data: any, sessionTimeout: any, tenant: any) => {
    if (tenant === url?.subdomain?.recruitment) {
        Cookies.set('userSessionTimeout', sessionTimeout, {
            domain: url?.domains?.recruitment,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('userdetail', JSON.stringify(data?.data), {
            domain: url?.domains?.recruitment,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('usertoken', data?.data?.auth_token, {
            domain: url?.domains?.recruitment,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
    } else {
        // session
        Cookies.set('userSessionTimeout', sessionTimeout, {
            domain: url?.domains?.main,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('userSessionTimeout', sessionTimeout, {
            domain: url?.domains?.vigilance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('userSessionTimeout', sessionTimeout, {
            domain: url?.domains?.grievance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
        // user-details
        Cookies.set('userdetail', JSON.stringify(data?.data), {
            domain: url?.domains?.main,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('userdetail', JSON.stringify(data?.data), {
            domain: url?.domains?.vigilance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('userdetail', JSON.stringify(data?.data), {
            domain: url?.domains?.grievance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        // token
        Cookies.set('usertoken', data?.data?.auth_token, {
            domain: url?.domains?.main,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('usertoken', data?.data?.auth_token, {
            domain: url?.domains?.vigilance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });

        Cookies.set('usertoken', data?.data?.auth_token, {
            domain: url?.domains?.grievance,
            path: '/',
            secure: true,
            sameSite: 'None'
        });
    }
}