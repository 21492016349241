/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseBtn, ProfileSuccessIcon } from "../../common/svg/AllSvg";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../../../shared/utils/url";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

const ApplySuccess = () => {
    const applySuccess: any = useSelector((state: any) => state.data.applySuccess);
    const applyData: any = useSelector((state: any) => state.data.applyData);
    const [submitDownload, setSubmitDownload] = useState<boolean>(false)
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setApplySuccess(false))
            !!params?.lang ? navigate(`/${params?.lang}/`) : navigate("/")
        }
    };

    const downloadForm = async () => {
        try {
            setSubmitDownload(true)

            const res = await axios({
                url: `${url?.baseUrl + url.download_form}?applied_id=${applyData?.apply_id}`,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    "Authorization": 'Token' + ' ' + Cookies.get('usertoken')
                }
            });

            if (res && res.status === 200) {
                const fileURL = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${applyData?.apply_no}.pdf`);
                document.body.appendChild(fileLink);

                fileLink.click();
                fileLink.remove();
                setSubmitDownload(false)
            }
        } catch (error) {
            setSubmitDownload(false)
            console.error("error while download form", error);
        }
    }

    return (
        <>
            {applySuccess &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]" onClick={() => { dispatch(dataActions.setApplySuccess(false)); !!params?.lang ? navigate(`/${params?.lang}/`) : navigate("/") }}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] w-full md:w-[600px] xlx:w-[713px] h-[450px] xlx:h-[528px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12))]">
                            <div className="absolute top-[18px] right-[22px]">
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="rounded-full focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] min-w-[23px] h-[23px] flex items-center justify-center bg-[#e4e4e4] dark:bg-[#4c4e5e5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" autoFocus={true} onClick={() => { dispatch(dataActions.setApplySuccess(false)); !!params?.lang ? navigate(`/${params?.lang}/`) : navigate("/") }}>
                                    <CloseBtn />
                                </button>
                            </div>

                            <div className="w-full h-full flex flex-col items-center justify-center px-[22px]">
                                <ProfileSuccessIcon />
                                <h3 className="text-primaryText dark:text-white font-bold mt-[20px] xlx:mt-[30px] text-center">{t("recruitment.home.apply.success.title")}</h3>
                                <h5 className="uppercase text-primary font-bold mt-[5px] xlx:mt-[10px] text-center">{t("recruitment.home.apply.success.app_no")} - {applyData?.apply_no}</h5>
                                <h5 className="text-blackDull dark:text-textBlackDull font-medium text-center mt-[15px] xlx:mt-[20px]">{t("recruitment.home.apply.success.desc")}</h5>

                                <div className="flex items-center gap-[10px] mt-[20px] xlx:mt-[43px]">
                                    <button className="border border-primary xlx:h-[40px] h-[35px] flex items-center justify-center rounded-[6px] px-[20px] xlx:px-[29px] w-fit text-primary font-extrabold uppercase text-sm leading-snug focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]" onClick={() => { dispatch(dataActions.setApplySuccess(false)); !!params?.lang ? navigate(`/${params?.lang}/`) : navigate("/") }}>{t("recruitment.home.home")}</button>

                                    <button onClick={downloadForm} disabled={submitDownload} className="leading-snug uppercase !font-extrabold px-[18px] flex items-center justify-center gap-[8px] xlx:gap-[15px] w-fit text-sm bg-primary text-white rounded-[4px] h-[35px] xlx:h-[40px] focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e] disabled:opacity-[0.5] disabled:cursor-not-allowed disabled:shadow-none disabled:dark:shadow-none">
                                        {t("recruitment.home.apply.success.download_form")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default ApplySuccess;