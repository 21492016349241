import { useSelector } from "react-redux";
import Logo from "../../../assets/images/LoaderLogo.png"
import DarkLogo from "../../../assets/images/DarkLoaderLogo.png"

const Loader = () => {
    const theme: any = useSelector((state: any) => state.ui.theme);

    return (
        <div className="w-full h-screen flex items-center justify-center flex-col gap-[15px] bg-white dark:bg-bgDarkDull">
            <img src={theme === "dark" ? DarkLogo : Logo} alt="logo" className="w-[20%] md:w-[15%] lg:w-[10%] 2xl:w-[8%]" />
            <h1 className="text-primary font-black text-2xl max-w-[200px] text-center flex items-center uppercase gap-[2px]">Loading
                <div className="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </h1>
        </div>
    );
};

export default Loader;