import Redirect from "../../routes/redirect/Redirect";

export const getApp = (tenantData: any) => {
    const app = tenantData.find((item: any) => item.subdomain === window.location.hostname);
    const main = tenantData.find((item: any) => item?.main);
    if (!app) {
        window.location.hostname = main?.subdomain;
        return Redirect;
    }

    return app.app;
};